import { action, computed, makeObservable, observable, ObservableMap, values } from 'mobx';

import { UserService } from 'API';
import { IUserRead, TUserDocumentRead, TUserDocumentType } from 'interfaces/entities/IUser';
import { ListStore } from 'store/general/List/lIstStore';
import { IUserStore } from './types';
import makeRequest from '../../../API/makeRequest';

class UserStore extends ListStore<IUserRead, void> implements IUserStore {
	applicantProfileStatus: null | string = null;
	trainings: ObservableMap<number, TUserDocumentRead> = observable.map();
	careerPlans: ObservableMap<number, TUserDocumentRead> = observable.map();
	certificates: ObservableMap<number, TUserDocumentRead> = observable.map();
	applicants: ObservableMap<number, TUserDocumentRead> = observable.map();
	count_tests = 0;

	constructor() {
		super({
			APIFetchItems: UserService.fetchUsers,
			APIFetchItem: UserService.fetchUser,
		});

		makeObservable(this, {
			trainings: observable,
			careerPlans: observable,
			certificates: observable,
			applicantProfileStatus: observable,
			applicants: observable,

			trainingsList: computed,
			careerPlansList: computed,
			certificatesList: computed,
			applicantsList: computed,

			setDocuments: action,
			setDocument: action,
			clearDocuments: action,
		});
	}

	get trainingsList() {
		return values(this.trainings);
	}

	get careerPlansList() {
		return values(this.careerPlans);
	}

	get certificatesList() {
		return values(this.certificates);
	}

	get applicantsList() {
		return values(this.applicants);
	}

	setDocuments(documents: TUserDocumentRead[]) {
		documents.forEach((document) => this.setDocument(document));
	}

	setFriendWorkUrl(url: string) {
		this.setElement({
			...this.element,
			applicant_profile: {
				...this.element.applicant_profile!,
				friendwork_link: url,
			},
		});
	}

	setDocument(document: TUserDocumentRead) {
		const { type, id } = document;
		if (type === 'Повышение квалификации') this.trainings.set(id, document);
		else if (type === 'Карьерный план') this.careerPlans.set(id, document);
		else if (type === 'Сертификат') this.certificates.set(id, document);
		else if (type === 'Соискатели') this.applicants.set(id, document);
	}

	clearDocuments() {
		this.trainings.clear();
		this.careerPlans.clear();
		this.certificates.clear();
		this.applicants.clear();
	}

	async fetchCountTests() {
		this.setLoading(true);

		const response = await UserService.fetchCountOfUserTests();
		if('count_tests' in response) {
			this.count_tests = response.count_tests;
		}
	}

	async handleOpenTest(id:number) {
		this.setLoading(true);

		const response = await UserService.fetchOpenTest(id);

		if(response === true) {
			this.setLoading(false);
			return true;
		}

		this.setLoading(false);
	}

	async fetchDocuments(id: string) {
		this.applicantProfileStatus = null;
		this.setLoading(true);
		this.clearDocuments();

		const response = await UserService.fetchDocuments(id);

		if ('user_documents' in response) {
			this.setDocuments(response.user_documents);
			this.applicantProfileStatus = response.applicant_profile_status;
		}

		this.setLoading(false);
	}

	async fetchFriendWork(id: string) {
		return await UserService.fetchFriendWork(id);
	}
	async createDocument(id: string, document: FormData) {
		const response = await UserService.createDocument(id, document);

		if ('user_document' in response) {
			this.setDocument(response.user_document);
		}
		console.log(response);

		return response;
	}
	async updateDocument(id: string, documentId: string, document: FormData) {
		const response = await UserService.updateDocument(id, documentId, document);

		if ('user_document' in response) {
			this.setDocument(response.user_document);
		}
		console.log(response);

		return response;
	}
	async removeDocument(documentId: string,userId: string, type: TUserDocumentType) {
		const response = await UserService.deleteDocument(documentId,userId);

		if (response.status === 200) {
			if (type === 'Повышение квалификации') this.trainings.delete(parseInt(documentId));
			else if (type === 'Карьерный план') this.careerPlans.delete(parseInt(documentId));
			else if (type === 'Сертификат') this.certificates.delete(parseInt(documentId));
			else if ( type === 'Соискатели') this.applicants.delete(parseInt(documentId));
		}
	}

	async createUserToken(userId: number) {
		return await UserService.CreateUserToken(userId);
	}
}

export default UserStore;
