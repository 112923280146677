import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import useDebounce from 'hooks/useDebounce';
import { DatePicker, Select, TextField } from 'components/UI';
import AsyncSelect from '../../../Logic/AsyncSelect/AsyncSelect/AsyncSelect';

import { getSortDirection } from '../utils';

import { IOption } from 'components/UI/Select/types';
import { ITableHeaderItemProps } from './types';

import styles from './TableHeaderField.module.scss';

const TableHeaderField = ({ field, onSort, onFilter }: ITableHeaderItemProps) => {
	const [searchParams] = useSearchParams();

	const sortName = searchParams.get('sort_by');
	const sortDirection = getSortDirection(searchParams.get('sort_dir'));

	const onSortClick = () => {
		if (!field.hasSorting) return;

		onSort(field.name);
	};

	const getDefaultValueSelect = (isAsync?: boolean, options?: IOption[]) => {
		const valueInSearchParams = searchParams.get(field.name) ?? '';
		if(isAsync) {
			return  {value: valueInSearchParams, label: valueInSearchParams};
		} else {
			return options?.find((option) => option.value === valueInSearchParams);
		}
	};

	const onFilterDebounce = useDebounce(onFilter, 1500);

	const thisColumnInSorting = field.name === sortName;

	const labelClasses = clsx(styles.label, {
		[styles.sorting]: field.hasSorting,
		[styles.sortingAsc]: thisColumnInSorting && sortDirection === 'asc',
		[styles.sortingDesc]: thisColumnInSorting && sortDirection === 'desc',
	});

	const columnWidth = clsx(styles.column, {
		[styles.condition]: field.label === 'Состояние' && field.name === 'is_active',
		[styles.contentCell]: field.type === 'content'
	});

	return (
		<li className={columnWidth}>
			<span className={labelClasses} onClick={onSortClick}>
				{field.label}
			</span>

			{field.type === 'content' && (
				<p className={styles.content}><span>{field.contentTitle}</span> {field.content}</p>
			)}

			{field.type === 'textField' && (
				<TextField
					defaultValue={searchParams.get(field.name) ?? ''}
					onChange={(e) => onFilterDebounce(field.name, e.target.value)}
				/>
			)}

			{field.type === 'select' && (
				<Select
					options={field.options}
					defaultValue={getDefaultValueSelect(false,field.options)}
					onChange={(option: IOption | null) => onFilter(field.name, option?.value?.toString() ?? '')}
				/>
			)}

			{field.type === 'datePicker' && (
				<DatePicker
					defaultValue={searchParams.get(field.name) ?? ''}
					onChange={(date) => onFilter(field.name, date)}
				/>
			)}

			{field.type === 'asyncSelect' && (
				<AsyncSelect
					type={field.endPoint}
					name={field.name}
					selectValue={getDefaultValueSelect(true) ?? null}
					onSelectChange={(option)=>onFilter(field.name,option?.label?.toString() ?? '')}/>
			)}

			{field.type === 'dateRange' && (
				<div className={styles.dateRange}>
					<DatePicker
						onChange={(date) => onFilter('start_date_diapason', date)}
						defaultValue={searchParams.get('start_date_diapason') ?? ''}
					/>
					<span>—</span>
					<DatePicker
						onChange={(date) => onFilter('finish_date_diapason', date)}
						defaultValue={searchParams.get('finish_date_diapason') ?? ''}
					/>
				</div>
			)}
		</li>
	);
};

export default TableHeaderField;
