import { FC } from 'react';

import { FieldByType } from 'components/Logic';
import { Validation } from 'components/UI';

import { fieldOnChange } from 'utils/fieldOnChange';
import { checkConditionField } from 'utils/checkConditionField';

import { FieldsType } from 'components/Logic/FieldByType/types';
import { FieldListProps, FieldOnChangEventType } from './types';

import styles from './FieldList.module.scss';

const FieldList: FC<FieldListProps> = ({ formik, fields = [], onlyRead = false,additional }) => {
	return (
		<ul className={styles.list}>
			{fields
				.filter((field) => !onlyRead || (onlyRead && formik?.values?.[field.name]))
				.map((field: FieldsType, index: number) => {
					if (
						field.showCondition &&
						!checkConditionField({
							condition: field.showCondition,
							formikValues: formik.values,
						})
					)
						return null;
					return (
						<li key={index} className={styles.item}>
							<FieldByType
								field={field}
								value={formik?.values?.[field.name]}
								onChange={(event: FieldOnChangEventType) => fieldOnChange(event, field, formik)}
								className={onlyRead || field?.disabled ? styles.disabled : ''}
								onDelete={() => formik.setFieldValue('image', null)}
							/>

							<Validation
								touched={formik?.touched?.[field.name]}
								errors={formik?.errors?.[field.name]}
							/>
						</li>
					);
				})}
			{additional}
		</ul>
	);
};

export default FieldList;
