import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { FriendWorkSync } from 'components/Logic';

import { UserProps } from './types';

import styles from './Users.module.scss';

const User: FC<UserProps> = observer(({ item }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/read${location.search}`);
	};

	const onLinkToQualifications = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`/documents/${item.id}/update${location.search}`);
	};

	const userName =
		item.applicant_profile !== undefined
			? item.applicant_profile?.first_name + ' ' + item.applicant_profile?.last_name
			: item.employer_profile?.employer_name;

	const userRole = item.role === 'applicant' ? 'Соискатель' : 'Предприятие';

	const getTestStatus = (status: boolean | null) => {
		if(userRole === 'Соискатель') {
			switch (status) {
				case null:
					return <span className={styles.status}><span className={styles.null}></span> Не пройден</span>;
				case false:
					return <span className={styles.status}><span className={styles.false}></span> Новая попытка</span>;
				case true:
					return <span className={styles.status}><span className={styles.true}></span> Пройден</span>;
			}
		}
			return null;
	};

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{userName}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.email}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{userRole}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.created_at}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{getTestStatus(item.test_status)}</span>
			</div>
			<div className={styles.cell} onClick={onLinkToQualifications}>
				<div className='edit-icon-block'>
					<span className='update-text'>Документы</span>
				</div>
			</div>

			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Смотреть</span>
				</div>
			</div>
			<div className={styles.cell}>
				<FriendWorkSync
					userId={String(item.id)}
					friendWorkLink={item.applicant_profile?.friendwork_link}
					syncBtnDisabled={!item.applicant_profile?.resume_id}
					isForList={true}
				/>
			</div>
		</div>
	);
});

export default User;
