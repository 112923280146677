import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';

import { PageHeader, FieldList } from 'components/Layouts';
import { LinkBtn, Loader } from 'components/UI';

import { ReadPageProps } from './types';

import styles from './ReadPage.module.scss';
import { globalFormikConfig } from 'config/globalFormikConfig';

const ReadPage: FC<ReadPageProps> = ({ title = '', fields = [], fetchData, additionalButtons,additional }) => {
	const [isLoading, setLoading] = useState(true);
	const location = useLocation();

	useEffect(() => {
		if (typeof fetchData === 'function') {
			setLoading(true);

			fetchData()
				.catch((error) => console.log(error))
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
		}
	}, [fetchData]);

	const backLink = location.pathname.split('/').slice(0, -2).join('/') + location.search;

	const initialValues = fields.reduce((acc: any, field: any) => {
		const name = field.name;
		const value = field.value ?? '';

		acc[name] = value;

		return acc;
	}, {});

	const formik = useFormik({
		...globalFormikConfig,
		initialValues,
		enableReinitialize: true,
		onSubmit: () => {
			return;
		},
	});

	if (isLoading) {
		return (
			<section className={styles.createPage}>
				<PageHeader title={title} backLink={backLink} />

				<Loader />
			</section>
		);
	}

	return (
		<section className={styles.createPage}>
			<PageHeader title={title} backLink={backLink} />

			<form onSubmit={formik.handleSubmit} className={styles.form}>
				<FieldList formik={formik} fields={fields} onlyRead additional={additional} />

				<div className={styles.btns}>
					<LinkBtn to={backLink} theme='filled'>
						Закрыть
					</LinkBtn>

					{additionalButtons}
				</div>
			</form>
		</section>
	);
};

export default ReadPage;
