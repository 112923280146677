import {roleOptions, TableHeader, testStatuses, THeaderField} from 'components/Layouts';

import styles from './Users.module.scss';
import {useStore} from '../../hooks/useStore';
import {useEffect} from 'react';

const UsersTableHeader = () => {
	const store = useStore();

	useEffect(()=>{
		store.users.fetchCountTests();
	},[]);
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Имя / название компании', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Почта', name: 'email', type: 'textField', hasSorting: true },
		{ label: 'Роль', name: 'role', type: 'select', options: roleOptions, hasSorting: true },
		{ label: 'Дата регистрации', name: 'created_at', type: 'datePicker', hasSorting: true },
		{ label: 'Нейротест', name: 'test_status', type: 'select', options: testStatuses,   hasSorting: true },
		{ label: '', name: '', content: store.users.count_tests?.toString(),contentTitle:'Прошли нейротест, человек:',type: 'content' }
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default UsersTableHeader;
