import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ReadPage } from 'components/Layouts';
import {Button, LinkBtn, Modal} from 'components/UI';
import { FriendWorkSync, FriendWorkLink } from 'components/Logic';

import { FieldsType } from 'components/Logic/FieldByType/types';

import { staticLinks } from 'config/routingLinks';

import styles from './Users.module.scss';
import {AsyncButton} from '../../components/Form';
const UserRead: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.users.element;
	const isApplicant = item.applicant_profile !== undefined;
	const friendWorkLink = item.applicant_profile?.friendwork_link;

	const fetchData = useCallback(async () => {
		await store.users.fetchItem(id);
	}, [id]);

	const authAsUser = async () => {
		const response = await store.users.createUserToken(item.id);

		if ('token' in response) {
			window.open(
				`${process.env.REACT_APP_FRONTEND_URL}${!isApplicant ? '/glavnaya-rabotodatelya': '/'}?admin_auth_token=${response.token}`,
				'_blank',
			);
		}
	};

	const employerFields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'id',
			value: item.employer_profile?.id?.toString(),
			label: 'ID',
		},
		{
			type: 'textfield',
			name: 'employer_name',
			value: item.employer_profile?.employer_name,
			label: 'Название компании',
		},
		{
			type: 'textfield',
			name: 'website',
			value: item.employer_profile?.website,
			label: 'Сайт',
		},
		{
			type: 'textfield',
			name: 'employer_address',
			value: item.employer_profile?.employer_address,
			label: 'Адрес',
		},
		{
			type: 'textfield',
			name: 'number_of_vacancies',
			value: item.employer_profile?.number_of_vacancies?.toString(),
			label: 'Количество вакансий',
		},
		{
			type: 'textfield',
			name: 'is_investment_project',
			value: item.employer_profile?.is_investment_project ? 'Да' : 'Нет',
			label: 'Инвестиционный проект',
		},
		{
			type: 'textfield',
			name: 'sphere',
			value: item.employer_profile?.sphere?.name?.toString() ?? 'Отсутствует',
			label: 'Направление',
		},
	];

	const profile = item?.applicant_profile;
	const fullName = [profile?.last_name, profile?.first_name, profile?.patronymic_name]
		.filter(Boolean)
		.join(' ');

	const applicantFields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'full_name',
			value: fullName,
			label: 'ФИО',
		},
		{
			type: 'textfield',
			name: 'phone',
			value: item.applicant_profile?.phone,
			label: 'Телефон',
		},
		{
			type: 'textfield',
			name: 'email',
			value: item.email,
			label: 'Почта',
		},
		{
			type: 'textfield',
			name: 'sex',
			value: item.applicant_profile?.sex,
			label: 'Пол',
		},
		{
			type: 'textfield',
			name: 'birthday',
			value: item.applicant_profile?.birthday,
			label: 'Дата рождения',
		},
		{
			type: 'textfield',
			name: 'status',
			value: item.applicant_profile?.status,
			label: 'Статус',
		},
		{
			type: 'textfield',
			name: 'situation',
			value: item.applicant_profile?.situation ?? 'Отсутствует',
			label: 'Положение',
		},
		{
			type: 'textfield',
			name: 'driving_license_categories',
			value: item.applicant_profile?.driving_license_categories?.join(', '),
			label: 'Категории прав',
		},
		{
			type: 'textfield',
			name: 'is_have_car',
			value: item.applicant_profile?.is_have_car ? 'Есть' : 'Отсутствует',
			label: 'Наличие транспортного средства',
		},
		{
			type: 'textfield',
			name: 'citizenship',
			value: item.applicant_profile?.citizenship ?? 'Отсутствует',
			label: 'Гражданство',
		},
		{
			type: 'textfield',
			name: 'resume_id',
			value: item.applicant_profile?.resume_id?.toString(),
			label: 'ID резюме',
		},
		{
			type: 'textfield',
			name: 'name_of_the_legal_representative',
			value: item.applicant_profile?.name_of_the_legal_representative,
			label: 'ФИО законного представителя ',
		},
		{
			type: 'textfield',
			name: 'phone_of_the_legal_representative',
			value: item.applicant_profile?.phone_of_the_legal_representative,
			label: 'Телефон законного представителя',
		},
	];

	const resumeLink = staticLinks.resumes + '/' + item.applicant_profile?.resume_id + '/update';
	const employerLink = staticLinks.employers + '/' + item.employer_profile?.id + '/update';

	const additionalButtons = (
		<>
			{isApplicant && item.applicant_profile?.resume_id && (
				<LinkBtn to={resumeLink} theme='outlined' rel='noreferrer' target='_blank'>
					Анкета пользователя
				</LinkBtn>
			)}
			{!isApplicant && (
				<LinkBtn to={employerLink} theme='outlined' rel='noreferrer' target='_blank'>
					Предприятие пользователя
				</LinkBtn>
			)}
			<Button theme='outlined' onClick={authAsUser}>
				Авторизация за пользователя
			</Button>

			{isApplicant && (
				<FriendWorkSync
					userId={String(item.id)}
					friendWorkLink={item.applicant_profile?.friendwork_link}
					syncBtnDisabled={!item.applicant_profile?.resume_id}
					isForList={false}
				/>
			)}
			{isApplicant && friendWorkLink && <FriendWorkLink link={friendWorkLink} />}
		</>
	);

	const onOpenTest = async () => {
		if(id) {
			const response = await store.users.handleOpenTest(+id);
		};
	};

	const additionalForm = (
		<div className={styles.additional}>
			<h3>Нейротест</h3>
			<p>Открыть повторное прохождение?</p>
			<AsyncButton onClick={onOpenTest} isLoading={store.users.isLoading}>Открыть</AsyncButton>
		</div>
	);

	if (store.users.fetchItem === undefined) return null;

	return (
		<ReadPage
			additional={additionalForm}
			title='Пользователи / Просмотр'
			fields={isApplicant ? applicantFields : employerFields}
			fetchData={fetchData}
			additionalButtons={additionalButtons}
		/>
	);
});

export default UserRead;
