import { IOption } from 'components/UI/Select/types';

export const isApprovedOptions: IOption[] = [
	{ label: 'Все', value: '' },
	{ label: 'Отказано', value: '0' },
	{ label: 'Опубликовано', value: '1' },
	{ label: 'На рассмотрении', value: '2' },
];

export const isResumeStatusOptions: IOption[] = [
	{ label: 'Все', value: '' },
	{ label: 'Школьник', value: 'Школьник' },
	{ label: 'Студент', value: 'Студент' },
	{ label: 'Специалист', value: 'Специалист' },
];

export const typeOptions: IOption[] = [
	{ label: 'Все', value: '' },
	{ label: 'Вакансии', value: 'Vacancy' },
	{ label: 'Анкеты', value: 'Resume' },
	{ label: 'Предприятия', value: 'EmployerProfile' },
	{ label: 'Курсы', value: 'Course' },
];

export const roleOptions: IOption[] = [
	{ label: 'Все', value: '' },
	{ label: 'Соискатель', value: 'applicant' },
	{ label: 'Предприятие', value: 'employer' },
];

export const testStatuses: IOption[] = [
	{ label: 'Все', value: '' },
	{ label: 'Пройден', value: true },
	{ label: 'Не пройден', value: 'null' },
	{ label: 'Новая попытка', value: false },
];

export const activeOptions: IOption[] = [
	{ label: 'Все', value: '' },
	{ label: 'Активно', value: '1' },
	{ label: 'Неактивно', value: '0' },
];

export const reviewedOptions: IOption[] = [
	{ label: 'Все', value: '' },
	{ label: 'Просмотрено', value: '1' },
	{ label: 'Не просмотрено', value: '0' },
];

export const careerGuidanceTypeOptions: IOption[] = [
	{ label: 'Все', value: '' },
	{ label: 'Человек - человек', value: 'Человек-человек' },
	{ label: 'Человек - природа', value: 'Человек-природа' },
	{ label: 'Человек - техника', value: 'Человек-техника' },
	{ label: 'Человек - знаковая система', value: 'Человек-знаковая система' },
	{ label: 'Человек - художественный образ', value: 'Человек-художественный образ' },
];
